section{
  .page{
   padding-top: 15px;
  }
    .section-menu{
      padding: 15px 0;
      ul{
        display: flex;
        margin: 0 -16px;
        align-items: center;
        li{
          list-style: none;
          padding: 0 16px;
          a{
            text-decoration: none;
            border-bottom: 2px solid #048dd3;
            color: #048dd3;
            font-size: 20px;
            display: inline-block;
            position: relative;
            &:after{
              content: "\f105";
              position: absolute;
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              text-rendering: auto;
              line-height: 1;
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              top: 7px;
              right: -20px;
              color: #4D5766;
              font-size: 17px;
            }
          }
          span{
            color: #4D5766;
            font-size: 20px;
          }
        }
      }
    }
    .city-details-tour{
      margin: 0 -15px;
    }
  }
  