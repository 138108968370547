
  .menu-contacts{
    padding-top: 40px;
    ul{
      display: flex;
      align-items: center;
      li{
        padding: 0 28px;
        list-style: none;
        &:first-child{
          padding: 0;
        }
        a{
          text-decoration: none;
          font-size: 20px;
          color: #4D5766;
          &.active{
            color: #048DD3;
            border-bottom: 2px solid #048DD3;
          }
          &:hover{
            color: #048DD3;
            border-bottom: 2px solid #048DD3;
          }
        }
      }
    }
  }
  .contact{
    padding-top: 35px;
    p{
      font-size: 25px;
      font-weight: bold;
      color: #048dd3;
    }
  }
  .all-contacts{
    display: flex;
    padding-top: 25px;
    
    .journey{
      flex: 0 0 50%;
      .mapdoor-img{
        width: 100%;
        height: 364px;
        
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .question{
        display: flex;
        justify-content: center;
        padding-top: 36px;
        a{
          text-decoration: none;
          font-weight: bold;
          font-size: 20px;
          color: #048DD3
        }
      }
      .consultation{
        display: flex;
        justify-content: center;
        text-align: center;
        padding-top: 17px;
        p{
          font-size: 18px;
          color: #4D5766;
          
        }
      }
      .your-contacts{
        padding-top: 30px;
        .form-block{
          display: flex;
          flex-wrap: wrap;
          margin: 0 -20px;
          .form-group{
            flex: 0 0 50%;
            max-width: 50%;
            padding: 15px 20px;
            input{
              width: 100%;
              padding: 10px 13px;
              border-radius: 5px;
              border: 1px solid #ABB5BA;
              color: #4d5766;
              font-size: 16px;
            }
            button{
              display: block;
              width: 100%;
              padding: 10px 0;
              background-color: #048dd3;
              color: #ffffff;
              border-radius: 5px;
              border: none;
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
      }
      .check{
        padding-top: 27px;
        label{
          display: flex;
          align-items: center;
          
          p{
            padding-left: 11px;
            font-size: 16px;
            color: #4d5766;
          }
          input{
            width: 28px;
            height: 28px;
          }
        }
      }
    }
    .tour-information{
      padding-left: 38px;
      .first{
        h3{
          color: #4D5766;
          font-size: 20px;
          font-weight: bold;
        }
        .first-contacts{
          ul{
            li{
              list-style: none;
             &.tel{
              a{
                text-decoration: none;
                color: #4D5766;
                
                font-size: 18px;
                line-height: 1.27;
              }
             }
            }
          }
        }
      }
      .messenger{
        padding-top: 30px;
        h3{
          color: #4D5766;
          font-size: 20px;
          font-weight: bold;
        }
      }
      .tourworld{
        a{
        color:#048DD3;
        font-size: 18px;
        text-decoration: none;
        }
      }
      .our-web{
        padding-top: 30px;
        h3{
          color: #4D5766;
          font-size: 20px;
          font-weight: bold;
        }
      }
      .web-menu{
        ul{
          display: flex;
          margin: 0 -7px;
          li{
            list-style: none;
            padding: 0 7px;
            a{
              img{
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
      .our-work{
        padding-top: 30px;
        h3{
          color: #4D5766;
          font-size: 20px;
          font-weight: bold;
        }
      }
      .work-time{
        p{
          color: #4D5766;
          font-size: 18px;
        }
      }
      .our-place{
        padding-top: 30px;
        h3{
          color: #4D5766;
          font-size: 20px;
          font-weight: bold;
        }
        p{
          color: #4D5766;
          font-size: 18px;
          padding-bottom: 30px;
        }
      }
    }
  }
  .yandex-map{
    padding-top: 50px;
    iframe{
      width: 100%;
      height: 462px;
    }
  }
  .work{
    display: flex;
    justify-content: center;
    padding-top: 56px;
    a{
      text-decoration: none;
      font-weight: bold;
      font-size: 25px;
      color: #048DD3;
    }
  }
  .employees{
    padding: 37px 0 183px 0;
    display: flex;
    margin: 0 -22px;
    .employees-item{
      padding: 0 10px;
      text-align: center;
      flex: 0 0 33%;
      max-width: 33%;
      h3{
      color: #4D5766;
      font-size: 20px;
      font-weight: bold;
      }
      p{
        font-size: 18px;
        color:#4D5766;
        padding-top: 19px;
      }
    }
  }