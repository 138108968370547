@media (max-width: 767px) {
  .container {
    width: 100%;
  }
  body {
    overflow-x: hidden;
  }
  //-------------------------------------------------town--------------------------------------------
  section .about-town {
    display: initial;
  }
  section .about-town .town-info .town-place ul {
    padding: 25px 0;
  }
  section .about-town .town-travel {
    padding: 25px 0;
  }
  .numibia-tour .country-ticket .request a{
    width: 100%;
    text-align: center;
    padding: 7px;
  }
  //-------------------------------------------------town---------------------------------------------
  //----------------------------------------------------------country-----------------------------------
  .about-country {
    display: initial;
  }
  .about-country .numibia-tour .country-ticket {
    padding: 30px 0;
  }
  .tour-wrap .tour {
    top: -700px;
  }
  .about-country .country-info .country-place {
    padding-top: 15px;
  }
  .about-country .country-info {
    padding-right: 0;
  }
  
  //----------------------------------------------------------country------------------------------------
  //---------------------------------------------------------------contacts----------------------------------------
  .employees .employees-item h3{
    padding-top: 15px;
  }
  .employees .employees-item p {
    font-size: 14px;
  }
  .all-contacts {
    display: initial;
  }
  .all-contacts .journey .check label {
    justify-content: center;
  }
  .all-contacts .tour-information {
    padding: 25px 0 0 0;
  }
  .employees {
    display: block;
  }
  .employees .employees-item {
    max-width: 100%;
  }
  .all-contacts .tour-information .web-menu ul li a img{
    width: 20px;
    height: 20px;
  }
  //---------------------------------------------------------------contacts------------------------------------------
  //-------------------------------------------------------------homepage-----------------------------------------

  body {
    padding-top: 178px;
  }
  header {
    padding-bottom: 0;
  }
  header .header-info .header-logo .logo {
    width: 130px;
  }
  header .header-info .header-logo .logo-text,
  header .header-info .header-contacts .contacts .message,
  header .header-info .header-contacts .contacts .web-sites,
  header .header-info .header-contacts .adress,
  header .header-info .header-contacts .header-menu {
    display: none;
  }
  .mobile-menu {
    display: block;
    background-color: #048dd3;
    position: relative;
    .mobile-content {
      padding: 10px 0;
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          list-style: none;
          &:first-child {
            a {
              font-weight: bold;
              font-size: 15px;
              text-transform: uppercase;
              color: #ffffff;
            }
          }
          &:last-child {
            a {
              font-size: 15px;
              color: #ffffff;
              text-decoration: none;
              font-weight: normal;
              img {
                width: 22px;
                height: 18px;
                vertical-align: middle;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    .menu-content {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      top: 100%;
      background-color: #048dd3;
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s;
      &.active {
        padding: 25px 15px;
        max-height: 400px;
      }
      .close-menu {
        position: absolute;
        right: 15px;
        top: 15px;
        text-decoration: none;
        font-size: 45px;
        color: #ffffff;
      }
      .menu-top {
        ul {
          li {
            list-style: none;
            a {
              font-weight: bold;
              line-height: 30px;
              font-size: 15px;
              text-transform: uppercase;
              color: #ffffff;
            }
          }
        }
      }
      .menu-foot {
        padding-top: 10px;
        p {
          font-size: 16px;
          color: #ffffff;
        }
        .soc-icons {
          display: flex;
          padding-bottom: 10px;
          p {
            margin-right: 15px;
          }
          ul {
            display: flex;
            li {
              list-style: none;
              padding-right: 10px;
              a {
                text-decoration: none;
                img {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
  header .header-info .header-contacts {
    padding: 0;
  }
  header .header-info .header-contacts .contacts .our-contacts ul li.tel a {
    font-size: 16px;
  }
  .mobile-hidden {
    display: none;
  }
  .owl-carousel .item {
    position: relative;
  }
  .owl-carousel .item1 {
    margin-bottom: 154px;
  }
  .owl-carousel .item2 .spain {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
  }
  .owl-carousel .item2 .spain .more {
    display: none;
  }
  .owl-carousel .item3 .christmas {
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    padding: 15px;
  }
  .owl-carousel .item1 .nice {
    position: absolute;
    top: 100%;
    padding: 15px;
  }
  .owl-carousel .item1 .nice .individual h3,
  .owl-carousel .item1 .nice .individual p {
    font-size: 14px;
    color: #048dd3;
    text-shadow: none;
  }
  .tour .choose-tour .tours a {
    font-size: 13px;
  }
  .tour .map .our-tours .tour-type ul li a {
    padding: 5px;
  }
  .city-details-tour .sights .city-details-sights .city-details-text h2 {
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .city-details-tour .sights .city-details {
    height: 72px;
    overflow: hidden;
  }
  .city-details-tour .sights .city-details-information p {
    font-size: 11px;
  }
  .city-details-tour .sights .city-details-information .discover-more a {
    font-size: 15px;
  }
  .owl-carousel .item1 .nice .carnaval h2,
  .owl-carousel .item2 .spain .spain-tour h2,
  .owl-carousel .item3 .christmas h4,
  .owl-carousel .item3 .christmas h3 {
    text-shadow: none;
    font-weight: bold;
    font-size: 25px;
    text-align: center;
    color: #048dd3;
  }
  .owl-carousel .item3 .christmas h3 {
    padding-top: 0;
    font-size: 18px;
  }
  .owl-carousel .item3 .christmas h4 {
    font-size: 16px;
  }
  .owl-carousel .item1 .nice .carnaval p,
  .owl-carousel .item3 .christmas h5,
  .owl-carousel .item3 .christmas h6 {
    text-shadow: none;
    font-size: 16px;
    text-align: center;
    color: #000000;
    font-weight: normal;
  }
  .owl-carousel .item3 .christmas h5,
  .owl-carousel .item3 .christmas h6 {
    padding-top: 0;
  }
  .owl-carousel .item3 .christmas h5 {
    display: none;
  }
  .owl-carousel .item1 .nice .individual {
    padding: 0;
  }
  .owl-carousel .owl-nav button {
    top: 35.5vw;
  }
  .owl-carousel .item {
    height: 69vw;
  }
  .owl-carousel .item1 .nice .more {
    display: none;
  }
  .owl-carousel .owl-dots {
    bottom: auto;
    top: calc(69vw - 11px);
    background-color: #ffffff;
  }
  .owl-carousel .owl-dots button {
    border: 2px solid #048dd3;
  }
  .owl-carousel .owl-dots button.active {
    background-color: #048dd3;
  }
  .tour .choose-tour {
    flex-wrap: wrap;
  }
  .tour .choose-tour form {
    width: 100%;
  }
  .tour .choose-tour .form-group {
    width: 100%;
  }
  .tour .choose-tour .tours,
  .tour .choose-tour .random-tour {
    padding-top: 10px;
  }
  .tour .map .our-tours .all-tours {
    border: none;
    flex-wrap: wrap;
    height: auto;
  }
  .tour .map .our-tours {
    padding-top: 60px;
  }
  .tour .map .our-tours .all-tours .tour-menu {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 10px 0;
  }
  .tour .map {
    background-position: center;
  }
  .page {
    padding-top: 20px;
    display: block;
  }
  .page ul {
    padding-left: 0;
    padding-top: 10px;
  }
  .page ul li {
    padding-left: 0;
    padding-right: 6px;
  }
  section .city-details-tour {
    padding: 20px;
  }
  .city-details-tour .sights {
    padding: 0;
    padding-bottom: 10px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  footer .information .footer-info {
    display: block;
  }
  footer .information .footer-info .footer-logo {
    display: flex;
  }
  footer .information .footer-info .our-logo {
    max-width: 130px;
  }
  footer .information .footer-info .footer-contacts {
    display: none;
    &.mobile {
      display: block;
    }
  }
  footer .information .footer-info .footer-contacts {
    text-align: left;
  }
  footer .information .footer-adress {
    padding: 10px 0;
  }
  footer .information .footer-info .footer-menu ul {
    padding: 0;
    padding-right: 15px;
  }
  footer .actise-group .footer-end {
    display: block;
    text-align: center;
  }
  footer .actise-group .footer-end .group {
    display: block;
  }
  footer .footer-information .about-us {
    display: block;
    text-align: center;
  }
  footer .footer-information .about-us .our-partners .uon {
    display: block;
  }
  footer .footer-information .about-us .our-partners .uon .uon-text p {
    font-size: 14px;
  }
  footer .footer-information .about-us .our-partners {
    align-items: center;
    padding: 10px 0;
  }

  .modal .call-back {
    padding: 10px;
  }
  .modal .call-back .order-call h3 {
    font-size: 20px;
  }
  .modal .call-back .send a {
    font-size: 18px;
  }
  .modal .call-back .form-block .form-item,
  .modal .call-back .form-block .form-group {
    padding: 10px 0;
  }
  .modal-content .offers h3 {
    font-size: 15px;
  }
  .modal-content .offers p {
    font-size: 12px;
  }
  .modal-content .form-block .form-group {
    padding: 10px;
  }
  .modal-content .bonus a {
    font-size: 14px;
  }
  .modal-content .check {
    padding-left: 10px;
  }
  section .country-details-background .tour-dates {
    align-items: center;
  }
  section .country-details-background .tour-dates .date p {
    font-size: 17px;
  }
  section .country-details-menu ul li span {
    font-size: 12px;
  }
  section .country-details-menu ul li a {
    font-size: 16px;
  }
  section .tour-price .price-menu .first-menu ul li {
    font-size: 12px;
  }
  section .tour-price .price-menu .second-menu ul li {
    font-size: 12px;
  }
  //-------------------------------------------------------------homepage-----------------------------------------
  //---------------------------------------------------------------tour----------------------------------------------
  section .second-day p {
    color: #4d5766;
  }
  section .trip-country-details .newyear-tour{
    padding-top: 50px;
  }
  section .trip-country-details {
    display: initial;
  }
  .tour-consuldation {
    padding-bottom: 35px;
  }
  section .about-carnival .left img {
    width: 14px;
    height: 14px;
  }
  section .about-carnival .left {
    left: -15px;
  }
  section .about-carnival p img {
    width: 14px;
    height: 14px;
  }
  section .country-details-background .tour-dates .cristmas-tour p {
    font-size: 11px;
  }
  section .country-details-background .tour-dates .cristmas-tour h3 {
    font-size: 15px;
  }
  //---------------------------------------------------------------tour----------------------------------------------

  .our-trip {
    display: block;
  }
  .trip-menu {
    padding-top: 50px;
  }
  .our-trip .trips-data {
    max-width: initial;
  }
  .our-trip
    .trips-data
    .trips-consultation
    .travel-data
    .form-block
    .form-group {
    display: flex;
    justify-content: center;
  }
  .our-trip .trips-data .trips-consultation .chek label {
    justify-content: center;
  }
  .our-trip .trips-data .trips-consultation .data-button {
    display: flex;
    justify-content: center;
  }
  .section-menu {
    padding: 15px;
  }
  .our-trip .trips-item {
    padding-right: 0;
    padding-bottom: 20px;
  }
  .our-trip .trips-item .trips-information {
    padding: 10px;
  }
  .our-trip .trips-item .trips-information .more-information .author {
    padding-right: 0;
  }
  .our-trip .trips-item .trips-information .more-information {
    padding: 10px 0;
    flex-wrap: wrap;
  }
  .our-trip .trips-data .trips-pla {
    font-size: 17px;
  }
  section .trip-country-details .country-details-info .new-year{
    height: auto;
  }
  section .trip-country-details .country-details-info{
    max-width: 100%;
    flex: 1;
    padding-right: 0;
  }
  section .trip-country-details .newyear-tour .get-ticket .menu ul li a{
    font-size: 12px;
  }
  section .trip-country-details .gallery{
    display: block;
  }
  section .trip-country-details .gallery .gallery-img{
    width: 100%;
    margin: 0 auto;
    max-width: 300px;
  }
  section .trip-country-details .newyear-tour{
    max-width: initial;
  }
  section .tour-price .price-menu{
    display: block;
  }
  section .tour-price .price{
    padding-top: 0;
  }
  section .tour-price .price-menu .second-menu{
    padding-left: 0;
  }
  section .trip-country-details .tour-consuldation .trips-consultation{
    padding: 10px;
  }
  section .trip-country-details .tour-consuldation .trips-consultation .travel-data{
    padding-top: 0;
  }
  section .trip-country-details .tour-consuldation .trips-consultation .travel-data .form-block .form-group input{
    width: 100%;
  }
  section .trip-country-details .tour-consuldation .trips-consultation .data-button button{
    width: 100%;
  }
  section .about-town .town-info{
    padding-right: 0;
  }
  section .about-town .town-info .town-images .place-img{
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
  section .about-town .town-travel{
    margin: 0 auto;
  }
  section .tour-price .price-menu .first-menu {
    padding-bottom: 15px;
  }
  section .trip-country-details .newyear-tour .request a {
    width: 100%;
    padding: 7px;
    text-align: center;
  }
  .numibia-tour .country-ticket{
    padding-bottom: 20px;
  }
  section .country-details-background .tour-dates {
    .cristmas-tour {
      h3, p {
        font-size: 18px;
      }
    }
  }
}
@media #{$xs} {
  .our-trip .trips-data .trips-consultation .trips-travel p{
    font-size: 14px;
  }
  .our-trip .trips-data .trips-consultation .chek label p{
    font-size: 15px;
  }
  .our-trip .trips-data .trips-place{
    padding: 20px 0;
  }
  .our-trip .trips-data .trips-consultation{
    position: static;
  }
  .our-trip .trips-data .trips-place p{
    font-size: 15px;
  }
  .all-contacts .tour-information .web-menu ul li a img{
    width: 22px;
    height: 22px;
  }
  section .trip-country-details .newyear-tour .get-ticket .menu ul li a{
    font-size: 15px;
  }
  section .trip-country-details .newyear-tour .get-ticket .menu ul li a.active{
    font-size: 15px;
  }
  section .trip-country-details .newyear-tour .get-ticket .menu ul li a{
    padding: 10px 0;
  }
  section .trip-country-details .newyear-tour .request a{
    text-align: center;
  }
  //----------------------------------------------------------country-----------------------------------
  .section-menu ul li {
    padding: 0 5px;
  }
  .section-menu ul li a,
  .section-menu ul li span {
    font-size: 14px;
  }
  .section-menu ul li a:after {
    top: 6px;
    right: -9px;
    font-size: 13px;
  }
  .place-info p,
  .about-country .country-info .namibia-info ul li {
    font-size: 15px;
  }
  .about-country .country-info .country-img {
    display: block;
  }
  .about-country .country-info .country-img .place-img {
    width: 100%;
    margin: 0 auto;
    max-width: 300px;
  }
  //----------------------------------------------------------country-----------------------------------
  .our-trip .trips-item .trips-background1 h3 {
    font-size: 12px;
  }
  section .country-details-background .tour-dates {
    display: block;
    .cristmas-tour {
      padding-left: 0;
      padding-top: 15px;
    }
    .date {
      display: inline-block;
    }
  }
}
@media #{$sm} {
  .container{
    width: 538px;
  }
  .our-trip .trips-data .trips-consultation{
    position: static;
  }
  .our-trip .trips-data .trips-place{
    padding: 50px 0;
  }

}
@media #{$md} {
  .container {
    width: 738px;
  }

  //-------------------------------------------------------------tour-trips-----------------------------------------

  .our-trip {
    display: block;
  }
  .trip-menu {
    padding-top: 50px;
  }
  .our-trip .trips-data {
    max-width: initial;
  }
  .our-trip
    .trips-data
    .trips-consultation
    .travel-data
    .form-block
    .form-group {
    display: flex;
    justify-content: center;
  }
  .our-trip .trips-data .trips-consultation .chek label {
    justify-content: center;
  }
  .our-trip .trips-data .trips-consultation .data-button {
    display: flex;
    justify-content: center;
  }
  .our-trip .trips-data .trips-place p {
    font-size: 25px;
    padding-bottom: 25px;
  }
  .our-trip .trips-data .trips-consultation{
    position: static;
  }
  .our-trip .trips-data .trips-place{
    padding-top: 50px;
  }

  //---------------------------------------------------------------tags-----------------------------------------------

  section .tags-menu {
    padding: 50px 0;
  }
  //----------------------------------------------------------------tags--------------------------------------------------
  //----------------------------------------------------------------tourprint-----------------------------------------------

  header .header-info {
    display: flex;
  }
  //----------------------------------------------------------------tourprint-----------------------------------------------------
  //--------------------------------------------------------------town-------------------------------------------------------

  section .town-menu {
    padding: 60px 0 27px;
  }
  section .about-town {
    display: block;
  }
  section .about-town .town-travel {
    max-width: initial;
  }
  //--------------------------------------------------------------town------------------------------------------------------------
  //----------------------------------------------------------------contact--------------------------------------------------------

  .all-contacts {
    display: block;
  }
  .all-contacts .journey .check label {
    justify-content: center;
  }
  .all-contacts .tour-information {
    padding: 50px 0;
  }
  .employees .employees-item p {
    font-size: 10px;
  }
 

  //-----------------------------------------------------------------contact---------------------------------------------------------------
  //------------------------------------------------------------------country--------------------------------------------------------------

  .about-country {
    display: block;
  }
  .about-country .numibia-tour .country-ticket {
    padding: 25px 0;
  }

  //---------------------------------------------------------------country---------------------------------------------------------------------
  //--------------------------------------------------------------------------tour---------------------------------------------------------------------------

  section .country-details-menu {
    padding-top: 50px;
  }
  section .country-details-menu ul li span {
    font-size: 13px;
  }
  section .country-details-menu ul li a {
    font-size: 16px;
  }
  section .country-details-menu ul li a:after {
    top: 5px;
  }
  section .country-details-background .tour-dates .cristmas-tour h3 {
    font-size: 18px;
  }
  section .country-details-background .tour-dates .cristmas-tour p {
    font-size: 14px;
  }
  section .country-details-background .tour-dates {
    align-items: center;
  }
  section .country-details-background .tour-dates .date p {
    font-size: 20px;
  }
  section .trip-country-details {
    display: block;
  }
  section .trip-country-details .newyear-tour {
    max-width: initial;
  }
  section .trip-country-details .newyear-tour .get-ticket .menu {
    justify-content: center;
  }
  section .trip-country-details .newyear-tour .get-ticket .menu a {
    font-size: 28px;
  }
  section .trip-country-details .newyear-tour .get-ticket .content ul li {
    padding-left: 30px;
  }
  section .trip-country-details .trips-place p {
    font-size: 32px;
  }
  section
    .trip-country-details
    .tour-consuldation
    .trips-consultation
    .travel-data
    .form-block
    .form-group {
    display: flex;
    justify-content: center;
  }
  section .trip-country-details .tour-consuldation {
    padding: 30px 0;
  }
  section .trip-country-details .tour-consuldation .trips-consultation .chek {
    display: flex;
    justify-content: center;
  }
  section
    .trip-country-details
    .tour-consuldation
    .trips-consultation
    .data-button {
    display: flex;
    justify-content: center;
  }
  footer .footer-information .about-us .footer-web ul li a img {
    width: 10px;
    height: 10px;
  }
  footer .footer-information .about-us .mailing .mailing-img a img {
    width: 15px;
    height: 15px;
  }
  footer .footer-information .about-us .our-partners .viza {
    width: 155px;
  }
  footer .footer-information .about-us .our-partners .uon .uon-img {
    width: 60px;
  }
  footer .footer-information .about-us .footer-web ul li a img {
    width: 10px;
    height: 10px;
  }
  footer .footer-information .about-us .mailing .mailing-text a {
    font-size: 11px;
  }
  footer .footer-information .about-us .our-partners .uon .uon-text p {
    font-size: 11px;
  }
  footer .footer-information .about-us .footer-web .web p {
    font-size: 11px;
  }
  footer .footer-information .about-us .our-partners {
    align-items: center;
  }
  footer .footer-information .about-us .our-partners .uon {
    align-items: center;
  }
  footer .footer-information .about-us .footer-web {
    align-items: center;
  }
  section .about-carnival .left {
    left: -24px;
  }
  //--------------------------------------------------------------------------------tour-----------------------------------------------------
  //    -------------------------------------------------------------homepage----------------------------------------------------
  body {
    padding-top: 275px;
  }

  .city-details-tour .sights {
    max-width: 50%;
  }
  header {
    padding-bottom: 15px;
  }
  header .header-info .header-logo .logo {
    width: 114px;
    height: 50px;
    margin: 0 auto;
  }
  header .header-info {
    display: block;
  }
  header .header-info {
    text-align: center;
    padding-bottom: 20px;
  }
  header .header-info .header-logo .logo {
    margin: 0 auto;
  }
  header .header-info .header-contacts {
    padding-left: 0;
  }
  .tour-wrap .tour {
    top: -392px;
  }

  header .header-info .header-contacts .contacts .message {
    padding-right: 40px;
  }
  header .header-info .header-contacts .contacts .web-sites {
    padding-right: 0;
  }
  header .header-info .header-contacts .contacts .message .message-text a {
    font-size: 12px;
  }
  header .header-info .header-contacts .contacts .our-contacts ul li.tel a {
    font-size: 16px;
  }
  header .header-info .header-contacts .contacts .our-contacts ul li a {
    font-size: 13px;
  }
  header .header-info .header-contacts .adress p {
    font-size: 12px;
  }
  header .header-info .header-contacts .header-menu ul li a {
    font-size: 12px;
  }
  .tour .map .our-tours .all-tours {
    padding: 30px 0;
    height: auto;
    flex-wrap: wrap;
  }
  .tour .map .our-tours .all-tours .tour-menu {
    flex: 0 0 33.33333333%;
  }
  .city-details-tour .sights .city-details-sights .city-details-text h2 {
    font-size: 12px;
  }
  .city-details-tour .sights {
    flex: 0 0 50%;
  }
  .city-details-tour .sights .city-details-information p {
    line-height: 15px;
    font-size: 14px;
  }
  footer .information .footer-info .our-logo {
    width: 130px;
    height: auto;
  }
  footer .information .footer-info .text p {
    font-size: 14px;
  }
  footer .information .footer-info .footer-menu ul {
    padding: 0 15px;
  }
  footer .information .footer-info .footer-menu ul li {
    line-height: 1.6;
  }
  footer .information .footer-info .footer-contacts ul li.tel a {
    font-size: 16px;
  }

  //    -------------------------------------------------------------homepage----------------------------------------------------
}
@media #{$lg} {
  .container {
    width: 962px;
  }
  //--------------------------------------------------------------tour---------------------------------------------------------
  section .trip-country-details .country-details-info {
    max-width: 555px;
    flex: 0 0 555px;
  }
  section .tour-price .price-menu .first-menu ul li {
    font-size: 12px;
  }
  section .about-carnival .left {
    left: -24px;
  }

  //------------------------------------------------------------tour----------------------------------------------------------------
  //------------------------------------------------------------contact-----------------------------------------------

  .employees .employees-item p {
    font-size: 10px;
  }
  //---------------------------------------------------------------contact-----------------------------------------------------------
  //----------------------------------------------------------country-------------------------------------------
  .about-country
    .numibia-tour
    .numibia-biuty
    a.sights
    .numibia-sights
    .numibia-text
    h2 {
    font-size: 18px;
  }
  .about-country .numibia-tour .numibia-biuty .numibia-picture {
    height: 265px;
  }
  //----------------------------------------------------------country-------------------------------------------------------
  //------------------------------------------------------------town--------------------------------------------------------

  section .country-details-menu {
    padding-top: 50px;
  }
  section .country-details-menu ul li span {
    font-size: 14px;
  }
  section .country-details-menu ul li a {
    font-size: 15px;
  }
  section .country-details-menu ul li a:after {
    top: 5px;
    font-size: 15px;
  }
  footer .footer-information .about-us .mailing .mailing-text a {
    display: flex;
    align-items: center;
  }
  footer .footer-information .about-us .footer-web ul li a img {
    width: 13px;
    height: 15px;
  }
  footer .footer-information .about-us .mailing .mailing-img a img {
    width: 17px;
    height: 16px;
  }
  section .country-details-background .tour-dates .cristmas-tour h3 {
    font-size: 25px;
  }
  section .tour-price .price-menu .first-menu ul li {
    font-size: 13px;
  }
  section .tour-price .price-menu .second-menu ul li {
    font-size: 13px;
  }
  //--------------------------------------------------------------------------town--------------------------------------------
  //    -------------------------------------------------------------homepage----------------------------------------------------
  body {
    padding-top: 180px;
  }
  header .header-info .header-logo .logo {
    width: 145px;
  }
  header .header-info .header-logo .logo-text {
    padding-top: 0;
  }
  header .header-info .header-contacts {
    padding-left: 35px;
  }
  header .header-info .header-contacts .contacts .web-sites {
    padding-right: 25px;
  }
  header .header-info .header-contacts .contacts .web-sites .web-text p {
    font-size: 13px;
    padding-right: 5px;
  }
  header .header-info .header-contacts .contacts .our-contacts ul li.tel a {
    font-size: 17px;
  }
  header .header-info .header-contacts .header-menu ul li a {
    font-size: 12px;
  }
  header .header-info .header-contacts .adress p {
    font-size: 14px;
  }
  header .header-info .header-contacts .contacts .message .message-text a {
    font-size: 14px;
  }
  .city-details-tour .sights .city-details-sights .city-details-text h2 {
    font-size: 14px;
  }
  .city-details-tour .sights .city-details-information p {
    line-height: 15px;
    font-size: 13px;
    padding-bottom: 0;
  }
  footer .information .footer-info .footer-menu ul li {
    line-height: 1.8;
  }
  //    -------------------------------------------------------------homepage----------------------------------------------------

  .pagination ul li a,
  .pagination ul li span {
    font-size: 16px;
  }
  .pagination .all-pag {
    font-size: 12px;
  }
  .pagination ul {
    padding-left: 10px;
    li {
      padding: 0 4px;
    }
  }
  .our-trip .trips-data .trips-consultation .travel-data .form-block .form-group input{
    width: 280px;
  }
  .our-trip .trips-data .trips-consultation .data-button button{
    width: 280px;
  }
  .our-trip .trips-data .trips-consultation .trips-travel p {
    font-size: 15px;
  }
  .our-trip .trips-data .trips-consultation .trips-travel h3{
    font-size: 20px;
  }
  .our-trip .trips-data .trips-consultation{
    padding: 20px 20px 30px;
  }
  .our-trip .trips-data .trips-consultation .travel-data{
    padding-top: 20px;
  }
}
@media (max-height: 810px) {
  .our-trip .trips-data .trips-consultation{
    padding: 25px 20px;
  }
  .our-trip .trips-data .trips-consultation .travel-data{
    padding-top: 0;
  }
  .our-trip .trips-data .trips-consultation .travel-data .form-block .form-group{
    padding: 10px 0;
  }
  .our-trip .trips-data .trips-consultation .chek{
    padding-top: 5px;
  }
  .our-trip .trips-data .trips-consultation .data-button{
    padding-top: 5px;
  }
  .our-trip .trips-data .trips-consultation{
    top: 220px;
  }
}


@media (min-width: 1200px) and (max-width: 1449px) {
  //-----------------------------------------------------------------------tour-------------------------------------------------------------------
  .container {
    width: 1170px;
  }
  section .trip-country-details .country-details-info {
    max-width: 760px;
    flex: 0 0 760px;
  }
  .container {
    width: 1170px;
  }
  .city-details-tour .sights .city-details-information p {
    font-size: 14px;
  }
  section .about-carnival .left {
    left: -24px;
  }
}
