.owl-carousel{
    width: 100%;
    position: relative;
    .owl-dots{
      content: '';
      position: absolute;
      display: flex;
      left: 50%;
      margin-left: -38px;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 25px;
      padding: 6px 8px;
      bottom: 25px;
      button{
        width: 10px;
        height: 10px;
        border-radius: 50px;
        border: 2px solid #4D5766;
        margin: 0 5px;
        &.active{
          background-color: #4D5766;
        }
      }
    }
    .owl-nav{
      button{
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -22px;
       i{
         background-color: #ffffff;
         width: 44px;
         height: 44px;
         border-radius: 50px;
         display: flex;
         justify-content: center;
         align-items: center;
         color: #048dd3;
         font-size: 30px;
       }
  
        &.owl-prev{
          left: 30px;
        }
        &.owl-next{
          right: 30px;
        }
      }
    }
    .item{
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 424px;
    }
    .item1{
      background-image: url(../images/nicca2.png);
      .nice{
        .individual{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 75px;
          h3{
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
            color: #ffffff;
            font-weight: bold;
            font-style: normal;
            font-size: 18px;
          }
          p{
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
            color: #ffffff;
            font-weight: bold;
            font-style: normal;
            font-size: 40px;
          }
        }
        .carnaval{
          justify-content: center;
          text-align: center;
          align-items: center;
          h2{
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
            color: #ffffff;
            font-weight: bold;
            font-style: normal;
            font-size: 60px;
          }
          p{
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
            color: #ffffff;
            font-weight: bold;
            font-style: normal;
            font-size: 18px;
            line-height: 1.33;
          }
        }
        .more{
          display: flex;
          justify-content: center;
          padding-top: 27px;
          a{
            text-decoration: none;
            color: #4d5766;
            font-size: 20px;
            font-weight: bold;
            font-style: normal;
            background-color: #ffffff;
            padding: 5px 13px;
            border-radius: 20px;
          }
        }
      }
    }
    .item2{
      background-image: url(../images/ispain.png);
      display: flex;
      align-items: center;
      justify-content: center;
      .spain{
        text-align: center;
        .spain-tour{
          h2{
            font-size: 60px;
            font-weight: bold;
            font-style: normal;
            color: #4D5766
  
          }
          
        }
        .more{
          padding-top: 24px;
          a{
            text-decoration: none;
            color: #4d5766;
            font-size: 20px;
            font-weight: bold;
            font-style: normal;
            background-color: #ffffff;
            padding: 5px 13px;
            border-radius: 20px;
          }
        }
      }
    }
    .item3{
      background-image: url(../images/christmas.png);
      display: flex;
      align-items: center;
      justify-content: center;
      .christmas{
        align-items: center;
        text-align: center;
        h3{
          display: flex;
          justify-content: center;
          font-family: 'Kurale';
          font-weight: normal;
          font-style: normal;
          line-height: 1;
          font-size: 40px;
          color: #ffffff;
          padding-top: 19px;
        }
        h4{
          display: flex;
          justify-content: center;
          font-size: 20px;
          font-weight: normal;
          font-style: italic;
          color: #ffffff;
        }
        h5{
          display: flex;
          justify-content: center;
          font-style: normal;
          font-weight: normal;
          line-height: 1.2;
          font-size: 20px;
          color: #ffffff;
          padding-top: 43px;
        }
        h6{
          display: flex;
          justify-content: center;
          font-style: italic;
          font-weight: normal;
          line-height: 1.1;
          font-size: 20px;
          color: #ffffff;
          padding-top: 66px;
        }
      }
    }
  }
  .tour{
    background-color: #F7F7ED;
    
    .choose-tour{
      display: flex;
      justify-content: space-between;
      padding: 34px 0 10px;
      align-items: center;
      .form-group{
        position: relative;
        width: 250px;
        label{
          position: absolute;
          right: 10px;
          top: 6px;
        }
        input{
          padding: 8px;
          border-radius: 5px;
          border: 1px solid #ABB5BA;
          color: #4D5766;
          width: 100%;
        }
        i{
          color: #4D5766;
        }
      }
      .tours{
       a{
        font-weight: bold;
        font-style: normal;
        font-size: 16px;
        color: #048dd3;
        text-decoration: none;
        text-transform: uppercase;
  
       }
      }
      .random-tour{
        display: flex;
        a{
          display: flex;
          margin: 0 5px;
          text-decoration: none;
          font-size: 16px;
          background-color: #048dd3;
          color: #ffff;
          align-items: center;
          padding: 4px 8px;
          border-radius: 5px;
          span{
            padding-right: 5px;
          }
          img{
            width: 25px;
            height: 25px;
            margin-right: 14px;
          }
        }
      }
    }
    .map{
      background-image: url(../images/Map.png);
      background-position: 0 65px;
      background-size: contain;
      background-repeat: no-repeat;
      padding-bottom: 25px;
      .our-tours{
        position: relative;
        padding-top: 32px;
        
        .all-tours{
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #abb5ba;
          border-radius: 5px;
          height: 179px;
          position: relative;
          .tour-menu{
            justify-content: center;
            flex: 0 0 16.666666667%;
            .tour-img{
              height: 62px;
              margin: 0 50px;
              img{
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            .img-text{
              text-align: center;
              a{
                display: inline-block;
                padding-top: 14px;
                text-decoration: none;
                font-size: 16px;
                color: #4D5766;
                font-style: normal;
                font-weight: 500;
              }
            }
          }
        }
        .tour-type{
          display: flex;
          content: "";
          position: absolute;
          top: 9px;
          left: 0;
          right: 0;
          justify-content: center;
          
          ul{
            display: flex;
            background-color: #abb5ba;
            border-radius: 25px;
            align-items: center;
            padding: 5px;
            li{
              list-style: none;
              text-decoration: none;
              
              a{
                display: inline-block;
                text-decoration: none;
                font-size: 18px;
                color: #ffffff;
                padding: 4px 16px;
                border-radius: 25px;
               &:hover{
                 background-color: #ffffff;
                 color: #4d5766;
               }
              }
            }
          }
        }
      }
    }
  }
  .page{
    display: flex;
    align-items: center;
    padding-top: 66px;
    &.pad-page{
      padding: 40px 0 93px 0;
    }
    .all-page{
      p{
        color: #4d5766;
        font-size: 16px;
      }
    }
    ul{
      display: flex;
      padding-left: 45px;
      li{
        list-style: none;
        padding: 0 9px;
        
        &:last-child {
          color: #048dd3;
          font-size: 16px;
          opacity: 0.5;
        }
        span{
          color: #048dd3;
          font-size: 20px;
          background-color: rgba(4, 141, 211, 0.15);
          padding: 2px 8px;
          border-radius: 5px;
          
        }
        a{
          text-decoration: none;
          color: #048dd3;
          font-size: 20px;
          padding: 2px 8px;
          border-radius: 5px;
          &:hover{
            background-color: rgba(4, 141, 211, 0.15);
          
          }
        }
      }
    }
  }
  .city-details-tour{
    display: flex;
    flex-wrap: wrap;
    padding-top: 59px;
    
    .sights{
        display: block;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        padding: 15px 15px;
        text-decoration: none;
        .sights-content{
          position: relative;
          height: 100%;
        }
        a{
          text-decoration: none;
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        .city-details-information{
          background-color: rgba(77, 87, 102, 0.7);
          border-radius: 5px;
          padding: 23px;
          opacity: 0;
          transition: all .5s;
          display: flex;
          flex-direction: column;
          min-height: 100%;
          .info-content {
            flex: 1 0 auto;
          }
          &:hover{
            opacity: 1;
          }
          p{
            font-weight: bold;
            font-size: 15px;
            padding-bottom: 10px;
            color: #ffffff;
          }
          .discover-more{
            padding-top: 5px;
            display: flex;
            justify-content: center;
            span, a{
              background: #048DD3;
              border-radius: 20px;
              color: #ffffff;
              text-decoration: none;
              font-size: 20px;
              font-weight: bold;
              padding: 5px 28px;
            }
          }
        }
       .city-details-sights{
         box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35);
         .city-details-text{
           padding-left: 17px;
           h4{
             font-size: 16px;
             color: #ffffff;
           }
           h2{
             font-size: 20px;
             color: #ffffff;
           }
         }
       }
        .city-details{
          display: flex;
          background-color: #8b98a0;
          padding: 16px 13px;
          width: 100%;
          align-items: center;
          border-radius: 5px 5px 0 0;
          overflow: hidden;
          .city-details-img{
            width: 54px;
            height: 40px;
            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
      .city-details-picture{
        position: relative;
        flex: 1 0 auto;
        background-size: cover;
        background-position: center;
        font-size: 0;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0 0 5px 5px;
        }
    }
  }