.mobile-menu{
  display: none;
}
header {
  border-bottom: 4px solid #048dd3;
  padding-bottom: 26px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 998;
  background-color: #ffffff;
  .header-info {
    display: flex;
    justify-content: space-between;
    padding-top: 26px;
    .header-logo {
      .logo {
        width: 185px;
        height: 106px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      .logo-text {
        padding-top: 15px;
        h2 {
          font-size: 16px;
          color: #4d5766;
          font-style: italic;
        }
      }
    }
    .header-contacts {
      flex: 1;
      padding-left: 80px;
      
      .contacts {
        display: flex;
        justify-content: flex-end;
        
        .message {
          display: flex;
          padding-right: 40px;
          padding-top: 6px;
          .message-text {
            a {
              img {
                width: 22px;
                height: 18px;
              }
              color: #048dd3;
              text-decoration: none;
              font-size: 16px;
              font-weight: normal;
              font-style: normal;
              padding-left: 7px;
            }
          }
        }
        .web-sites {
          display: flex;
          padding-right: 76px;
          padding-top: 6px;
          .web-text {
            p {
              font-size: 16px;
              font-weight: normal;
              font-style: normal;
              color: #4d5766;
              padding-right: 17px;
            }
          }
          ul {
            display: flex;
            li {
              list-style: none;
              padding: 0 8px;
              a {
                img {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }
        .our-contacts {
          text-align: right;

          ul {
            li {
              &.tel {
                a {
                  color: #048dd3;
                  font-weight: bold;
                  font-style: normal;
                  font-size: 22px;
                  line-height: 1.27;
                }
              }
              list-style: none;
              a {
                text-decoration: none;
                color: #048dd3;
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
              
              }
            }
          }
        }
      }
      .adress {
        display: flex;
        justify-content: flex-end;
        p {
          line-height: 1.5;
          font-size: 16px;
          color: #4d5766;
          font-weight: normal;
          font-style: normal;
        }
      }

      .header-menu {
        padding-top: 17px;

        ul {
          display: flex;
          justify-content: space-between;
          li {
            list-style: none;
            a {
              text-transform: uppercase;
              text-decoration: none;
              font-size: 15px;
              color: #048dd3;
              font-weight: bold;
              font-style: normal;
              border-bottom: 2px solid #048dd3;
              &:hover {
                color: #f2994a;
                border-bottom: 2px solid #f2994a;
              }
            }
          }
        }
      }
    }
  }
}
