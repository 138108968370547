footer {
  .information {
    background-color: #abb5ba;
    .footer-info {
      display: flex;
      justify-content: space-between;
      padding: 29px 0 14px 0;

      .our-logo {
        width: 174px;
        height: 99px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      .text {
        padding-top: 10px;
        p {
          font-weight: normal;
          font-style: italic;
          line-height: 1.25;
          color: #4d5766;
          font-size: 16px;
        }
      }
      .footer-menu {
        display: flex;
        ul {
          padding: 0 42px;
          li {
            list-style: none;
            line-height: 2.5;
            a {
              text-decoration: none;
              font-size: 18px;
              color: #ffffff;
            }
          }
        }
      }
      .footer-contacts {
        text-align: right;
        &.mobile{
          display: none;
        }
        ul {
          li {
            &.tel {
              list-style: none;
              line-height: 1.27;
              a {
                text-decoration: none;
                font-weight: bold;
                font-style: normal;
                letter-spacing: 1.1px;
                color: #048dd3;
                font-size: 22px;
              }
            }
          }
        }
      }
    }
    .footer-adress {
      padding-top: 26px;
      p {
        color: #4d5766;
        font-size: 16px;
      }
    }
  }
  .footer-information {
    background-color: #8b98a0;
    .about-us {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      .mailing {
        display: flex;
        align-items: center;
        .mailing-text {
          a {
            img {
              width: 22px;
              height: 18px;
              margin-right: 5px;
            }
            text-decoration: none;
            padding-left: 7px;
            font-size: 18px;
            color: #ffffff;
          }
        }
      }
      .our-partners {
        display: flex;

        .viza {
          width: 183px;
          height: 33px;

          img {
            object-fit: cover;
            width: 100%;
          }
        }
        .uon {
          display: flex;
          padding-left: 23px;
          padding-top: 8px;
          .uon-text {
            p {
              font-size: 16px;
              color: #ffffff;
            }
          }
          .uon-img {
            width: 63px;
            height: 33px;
            padding-left: 7px;
            img {
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
      .footer-web {
        display: flex;
        .web {
          p {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            color: #ffffff;
            padding-right: 17px;
          }
        }
        ul {
          display: flex;

          li {
            list-style: none;
            padding: 0 8px;

            a {
              img {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }
  }
  .actise-group {
    background-color: #4d5766;
    .footer-end {
      display: flex;
      justify-content: space-between;
      padding: 18px 0;
      .site-creation {
        a {
          text-decoration: none;
          font-size: 16px;
          color: #ffffff;
        }
      }
      .group {
        display: flex;
        p {
          color: #ffff;
          font-size: 16px;
        }
        a {
          text-decoration: none;
          font-size: 16px;
          color: #048dd3;
          padding-left: 5px;
        }
      }
    }
  }
}
