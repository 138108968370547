@charset "UTF-8";

/* helpers */
@import "helpers/helpers";

/* Main Styles */
body {
  font-size: $font-base;
  font-family: $fontGlobal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 199px;
}

/* fonts */
@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu-Bold.eot");
  src: url("../fonts/Ubuntu-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Ubuntu-Bold.woff2") format("woff2"), url("../fonts/Ubuntu-Bold.woff") format("woff"),
    url("../fonts/Ubuntu-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu.eot");
  src: url("../fonts/Ubuntu.eot?#iefix") format("embedded-opentype"), url("../fonts/Ubuntu.woff2") format("woff2"),
    url("../fonts/Ubuntu.woff") format("woff"), url("../fonts/Ubuntu.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu-Medium.eot");
  src: url("../fonts/Ubuntu-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Ubuntu-Medium.woff2") format("woff2"), url("../fonts/Ubuntu-Medium.woff") format("woff"),
    url("../fonts/Ubuntu-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu-Italic.woff2") format("woff2"), url("../fonts/Ubuntu-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Kurale";
  src: url("../fonts/Kurale-Regular.woff2") format("woff2"), url("../fonts/Kurale-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* end Main Styles */
@import "custom/custom";
/* header */
@import "./partials/header";

// pages
@import "./pages/homepage";
@import "./pages/contacts";
@import "./pages/our-trips";
@import "./pages/tourprint";
@import "./pages/town";
@import "./pages/tour";
@import "./pages/faq";
@import "./pages/country";
@import "./pages/tags";

// taqs

// !taqs

/* footer */
@import "./partials/footer";

/* responsive */

@import "custom/responsive";

////////////////////////////////////////////////////////////////* RETINA MEDIA*/

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 767px),
  only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 767px),
  only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 767px),
  only screen and (min-device-pixel-ratio: 2) and (max-width: 767px),
  only screen and (min-resolution: 192dpi) and (max-width: 767px),
  only screen and (min-resolution: 2dppx) and (max-width: 767px) {
}
