section {
  .faq-menu {
    ul {
      display: flex;
      margin: 0 -16px;
      align-items: center;
      li {
        list-style: none;
        padding: 0 16px;
        a {
          text-decoration: none;
          border-bottom: 2px solid #048dd3;
          color: #048dd3;
          font-size: 20px;
          display: inline-block;
          position: relative;
          &:after {
            content: "\f105";
            position: absolute;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            top: 7px;
            right: -20px;
            color: #4d5766;
            font-size: 17px;
          }
        }
        span {
          color: #4d5766;
          font-size: 20px;
        }
      }
    }
  }
  .faq {
    padding: 40px 0 105px;
    h3 {
      font-weight: bold;
      font-size: 25px;
      color: #048dd3;
    }
    ul {
      padding-top: 30px;
      li {
        position: relative;
        .description{
          width: 100%;
          z-index: 10;
          transition: all 0.5s;
          background-color: #ffffff;
          transform: translateY(-120%);
          p{
            font-size: 16px;
            color: #4D5766;
            padding: 10px 0;
            padding-left: 45px;
            
          }
        }
        margin-bottom: 20px;
        cursor: pointer;
        list-style: none;
        user-select: none;
        max-height: 32px;
        overflow: hidden;
        transition: all .5s;
        &.active{
          max-height: 1500px;
          overflow: visible;
          .description{
            transform: translateY(0);
          }
        }
        a {
          text-decoration: none;
          color: #048dd3;
          font-size: 18px;
          display: inline-block;
        }
        i {
          margin-right: 7px;
          background-color: #048dd3;
          font-size: 16px;
          color: #ffffff;
          border-radius: 50px;
          padding: 8px 9px;
        }
      }
    }
  }
}
