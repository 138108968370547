section{
    .town-menu{
      padding: 30px 0 27px;
      ul{
        display: flex;
        margin: 0 -16px;
        align-items: center;
        li{
          list-style: none;
          padding: 0 16px;
          a{
            text-decoration: none;
            border-bottom: 2px solid #048dd3;
            color: #048dd3;
            font-size: 20px;
            display: inline-block;
            position: relative;
            &:after{
              content: "\f105";
              position: absolute;
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              text-rendering: auto;
              line-height: 1;
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              top: 7px;
              right: -20px;
              color: #4D5766;
              font-size: 17px;
            }
          }
          span{
            color: #8B98A0;
            font-size: 20px;
          }
        }
      }
    }
   .town-img{
     background-image: url(../images/town.png);
     width: 100%;
     height: 360px;
     background-position: center;
     background-size: cover;
     background-repeat: no-repeat;
     display: flex;
     align-items: center;
     justify-content: center;
     h3{
      font-size: 35px;
      font-weight: 500;
      color: #4d5766;
     }
   }
}
  section{
   .about-town{
     display: flex;
     padding: 45px 0 87px;
     
     .town-info{
       max-width: 880px;
       padding-right: 30px;
       .town-place{
         ul{
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 5px;
           li{
             list-style: none;
             padding: 0 8px;
             margin: 7px 0;
             border-right: 2px solid #048dd3;
             a{
               text-decoration: none;
               color: #048dd3;
               font-size: 18px;
               display: inline-block;
               line-height: 1;
             }
             &:last-child{
                 border: none;
             }
           }
          }
        }
        .place-info{
          p{
            line-height: 1.33;
            color: #4d5766;
            font-size: 18px;
            padding-bottom: 18px;
          }
        }
        .town-images{
          display: flex;
          flex-wrap: wrap;
          margin: 0 -15px;
          .place-img{
            width: 189px;
            height: 191px;
            padding: 15px;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
      .town-travel{
        max-width: 400px;
        .get-ticket{
          background-color: rgba(247, 247, 237, 0.9);
          padding: 25px;
          border-radius: 10px;
          .send-message{
            h3{
              
              color: #048DD3;
              font-size: 18px;
              text-transform: uppercase;
            }
            p{
              color: #048DD3;
              font-size: 16px;
            }
          }
        }
        .manager{
          display: flex;
          padding-top: 25px;
          .manager-img{
            width: 115px;
            height: 115px;
            img{
              width: 100%;
              object-fit: cover;
            }
          }
          .manager-data{
            padding-left: 17px;
            h4{
              color: #4D5766;
              font-size: 16px;
            }
            h2{
              font-weight: bold;
              font-size: 18px;
              color: #4D5766;
            }
            ul{
              li{
                list-style: none;
                font-size: 16px;
                color: #4D5766; 
                span{
                  font-size: 16px;
                }
              }
            }
           
          }
        }
        .request{
          padding-top: 35px;
         display: flex;
         justify-content: center;
          a{
            text-decoration: none;
            background-color: #048dd3;
            padding: 7px 80px;
            border-radius: 25px;
            color: #ffffff;
          }
        }
        
      }
      
    }
  }
  