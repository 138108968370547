.our-trip{
    padding-top: 24px;
    display: flex;
    .photo-reports{
      .trip-menu{
        ul{
          display: flex;
          margin: 0 -8px;
          align-items: center;
          li{
            list-style: none;
            padding: 0 8px;
            
            a{
              text-decoration: none;
              font-size: 20px;
              color: #4D5766;
              
              &:hover{
                &.last{
                  color: #048DD3;
                  border-bottom: 2px solid #048DD3;
                }
              }
              &.active{
                color: #048DD3;
                border-bottom: 2px solid #048DD3;
              }
            }
          }
        }
      }
      .reports{
        padding-top: 48px;
        h3{
          color: #048DD3;
          font-size: 25px;
          font-weight: bold;
        }
      }
      .trip-text{
        padding-top: 44px;
        h3{
          color: #4D5766;
          font-weight: bold;
          font-size: 20px;
        }
      }
      .travel-text{
        padding-top: 48px;
        p{
          padding-bottom: 35px;
          color: #4D5766;
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
    .trips-item{
      padding-right: 42px;
      padding-bottom: 40px;
      .trips-background2{
        background-image: url(../images/russia_kamcatka1.png);
        width: 100%;
        height: 273px;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        h3{
          font-weight: bold;
          line-height: 1.2;
          font-size: 25px;
          padding: 32px 16px;
          color: #ffffff;
       
        }
      }
      .trips-background1{
        background-image: url(../images/russia_kamcatka.png);
        width: 100%;
        height: 273px;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        h3{
          font-weight: bold;
          line-height: 1.2;
          font-size: 25px;
          padding: 32px 16px;
          color: #ffffff;
        
        }
      }
      .trips-background{
        background-image: url(../images/kamcatka.png);
        width: 100%;
        height: 273px;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        h3{
          font-weight: bold;
          line-height: 1.2;
          font-size: 25px;
          padding:  32px 16px;
          color: #ffffff;
        
        }
      }
      .trips-information{
        padding-left: 32px;
        background-color: rgba(171, 181, 186, 0.15);
        
        .about-trips{
          padding-top: 23px;
          p{
            line-height: 1.44;
            font-style: italic;
            color: #4d5766;
            font-size: 18px;
          }
        }
        .more-information{
          padding: 62px 0 30px 0;
          display: flex;
          justify-content: space-between;
          .see-more{
            a{
              text-decoration: none;
              font-size: 18px;
              font-weight: bold;
              color: #ffffff;
              background-color: #048dd3;
              border-radius: 5px;
              padding: 8px 13px;
            }
          }
          .author{
            padding-right: 42px;
            p{
              font-size: 16px;
              font-weight: 500;
              color: #4d5766;
            }
          }
        }
      }
    }
    .trips-data{
      max-width: 400px;
      .trips-consultation{
        align-items: center;
        background-color:  #048dd3;
        padding: 28px 34px 37px;
        border-radius: 5px;
        position: sticky;
        top: 220px;
        .trips-travel{
          text-align: center;
          line-height: 30px;
          h3{
            font-weight: bold;
            font-size: 25px;
            color: #ffffff;
          }
          p{
            font-size: 18px;
            color: #ffffff;
          }
        }
        .travel-data{
          padding-top: 30px;
          .form-block{
            
            .form-group{
              padding: 15px 0;
              input{
                width: 330px;
                padding: 10px 13px;
                border-radius: 5px;
                border: 1px solid #ABB5BA;
                color: #4d5766;
                font-size: 16px;
              }
            }
          }
        }
        .chek{
          padding-top: 28px;
          label{
            display: flex;
            align-items: center;
            
            p{
              padding-left: 11px;
              font-size: 16px;
              color: #4d5766;
              line-height: 1;
              color: #ffffff;
            }
            input{
              width: 28px;
              height: 28px;
              
            }
          }
        }
        .data-button{
          padding-top: 24px;
          button{
            display: block;
            width: 330px;
            padding: 10px 0;
            background-color: #ABB5BA;
            color: #ffffff;
            border-radius: 5px;
            border: none;
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
      .trips-place{
        padding-top: 50px;
        text-align: center;
        align-items: center;
      p{
        color: #048dd3;
        font-size: 18px;
        span{
          &.fz24{
            font-size: 24px;
          }
          &.fz10{
            font-size: 10px;
          }
          &.fz35{
            font-size: 35px;
          }
        }
      }
      }
    }
  }