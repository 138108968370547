.container {
  width: 1250px;
  padding: 0 15px;
  margin: 0 auto;
}
.section-background {
  background-color: #f7f7ed;
  height: 14px;
}
.tour-wrap {
  position: relative;
  z-index: 5;

  .recruitman {
    display: flex;
    justify-content: center;

    a {
      text-decoration: none;
      color: #048dd3;
      font-weight: bold;
      font-size: 16px;
      background-color: #f7f7ed;
      padding: 4px 13px 11px;
      border-radius: 0 0 10px 10px;
      display: inline-block;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  &.active {
    display: flex;
  }
  .modal-content {
    background-color: #ffffff;
    width: 100%;
    max-width: 500px;
    border-radius: 5px;
    position: relative;
  }
  .call-back {
    padding: 38px 34px;

    .order-call {
      display: flex;
      justify-content: center;
      h3 {
        color: #048dd3;
        font-weight: bold;
        font-size: 25px;
      }
    }
    .form-block {
      .form-group {
        padding: 15px 20px;
        input {
          width: 100%;
          padding: 13px 20px;
          border-radius: 5px;
          border: 1px solid #abb5ba;
          color: #4d5766;
          font-size: 16px;
        }
      }
      .form-item {
        padding: 15px 20px;
        input {
          width: 100%;
          border-radius: 5px;
          border: 1px solid #abb5ba;
          color: #4d5766;
          font-size: 16px;
          padding: 13px 20px;
          padding-bottom: 180px;
        }
      }
    }
    .check {
      padding-top: 23px;
      label {
        display: flex;
        padding-left: 15px;
        align-items: center;
        p {
          padding-left: 11px;
          font-size: 16px;
          color: #4d5766;
        }
        input {
          width: 28px;
          height: 28px;
        }
      }
    }
    .send {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      a {
        text-decoration: none;
        font-weight: bold;
        font-size: 30px;
        background-color: #048dd3;
        border-radius: 25px;
        padding: 10px 40px;
        color: #ffffff;
      }
    }
  }
  .close {
    position: absolute;
    top: 22px;
    right: 22px;
    i {
      background-color: #048dd3;
      color: #ffffff;
      padding: 5px 8px;
      border-radius: 50px;
    }
  }
}
.tour-wrap {
  position: relative;
  .tour {
    transition: 0.5s;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: -3000px;
    &.active {
      top: 0;
    }
  }
  .close-tour {
    position: absolute;
    top: 0;
    right: 45px;
    i {
      background-color: #048dd3;
      color: #ffffff;
      padding: 12px 15px;
      border-radius: 50px;
    }
  }
}
.modal-content {
  padding: 0;
  .offers {
    h3 {
      display: flex;
      justify-content: center;
      text-align: center;
      font-weight: bold;
      font-size: 25px;
      color: #048dd3;
    }
    p {
      display: flex;
      justify-content: center;
      text-align: center;
      padding-top: 25px;
      font-size: 18px;
      color: #4d5766;
    }
  }
  .form-block {
    padding-top: 25px;
    .form-group {
      padding: 15px 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      input {
        width: 100%;
        padding: 13px 20px;
        border-radius: 5px;
        border: 1px solid #abb5ba;
        color: #4d5766;
        font-size: 16px;
      }
      textarea {
        width: 100%;
      }
    }
  }
  .check {
    padding-top: 23px;
    label {
      justify-content: center;
      display: flex;
      align-items: center;
      p {
        padding-left: 11px;
        font-size: 16px;
        color: #4d5766;
      }
      input {
        width: 28px;
        height: 28px;
      }
    }
  }
  .bonus {
    display: flex;
    justify-content: center;
    padding: 0 0 30px;
    a {
      text-decoration: none;
      font-weight: bold;
      font-size: 20px;
      color: #ffffff;
      background-color: #048dd3;
      padding: 10px 22px;
      border-radius: 25px;
    }
  }
}
.tour-modal {
  .modal-content {
    max-width: 795px;
    height: 786px;
    padding: 10px;
    user-select: none;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .close {
    position: absolute;
    top: -25px;
    right: -25px;
  }
}
.numibia-tour {
  max-width: 400px;
  padding-bottom: 40px;
  .country-tour {
    h3 {
      color: #048dd3;
      font-size: 25px;
      font-weight: bold;
    }
  }
  .numibia-biuty {
    padding-top: 15px;
    a {
      &.sights {
        display: block;
        text-decoration: none;
        .numibia-sights {
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35);
          .numibia-text {
            padding-left: 17px;
            h4 {
              font-size: 16px;
              color: #ffffff;
            }
            h2 {
              font-size: 20px;
              color: #ffffff;
            }
          }
        }
        .numibia {
          display: flex;
          background-color: #8b98a0;
          padding: 16px 13px;
          width: 100%;
          align-items: center;
          border-radius: 5px 5px 0 0;
          .numibia-img {
            width: 42px;
            height: 38px;
            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
    .numibia-picture {
      height: 280px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0 0 5px 5px;
      }
    }
  }
  .country-ticket {
    padding-top: 50px;
    .get-ticket {
      background-color: rgba(247, 247, 237, 0.9);
      padding: 25px;
      border-radius: 10px;
      .send-message {
        h3 {
          color: #048dd3;
          font-size: 18px;
          text-transform: uppercase;
        }
        p {
          color: #048dd3;
          font-size: 16px;
        }
      }
    }
    .manager {
      display: flex;
      padding-top: 25px;
      .manager-img {
        width: 115px;
        height: 115px;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .manager-data {
        padding-left: 17px;
        h4 {
          color: #4d5766;
          font-size: 16px;
        }
        h2 {
          font-weight: bold;
          font-size: 18px;
          color: #4d5766;
        }
        ul {
          li {
            list-style: none;
            font-size: 16px;
            color: #4d5766;
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
    .request {
      padding-top: 35px;
      display: flex;
      justify-content: center;
      a {
        text-decoration: none;
        background-color: #048dd3;
        padding: 7px 80px;
        border-radius: 25px;
        color: #ffffff;
      }
    }
  }
}
.scroll-link {
  position: fixed;
  right: 50px;
  bottom: 60px;
  z-index: 3;
  a {
    i {
      font-size: 30px;
      color: #ffffff;
      background-color: #048dd3;
      padding: 8px 15px;
      border-radius: 50px;
    }
  }
}
.d-none {
  display: none;
}

.fancybox-image {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}
.fancybox-lock .fancybox-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.main-content h1.page_h1 {
  font-size: 25px;
  font-weight: 700;
  color: #048dd3;
  /* margin-top:20px; */
  margin: 40px 0 20px 0;
}
.main-content p {
  margin-bottom: 21px;
}
.main-content .country-details-info p {
  color: #4d5766;
  line-height: 1.33;
  font-size: 18px;
}

.main-content .blog h1,
.error404 .main-content h1 {
  font-size: 24px;
  color: #008bd2;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-block;
}
.main-content .blog p {
  margin-bottom: 21px;
}
.error404 .main-content h1,
.error404 .main-content .single-content-inner {
  max-width: 400px;
  margin: 20px auto;
  display: block;
}
.error404 .main-content .single-content-inner {
  margin-bottom: 80px;
}

blockquote {
  font-style: italic;
  background-image: url(../images/left.png);
  background-repeat: no-repeat;
  padding-left: 100px;
  min-height: 100px;
}

a {
  color: #048dd3;
  text-decoration: none;
}

.single-content-inner ul,
.about-country ul {
  padding-left: 15px;
  margin-bottom: 21px;
}
.modal-content .form-block .form-group .cbcheckbox input {
  width: inherit;
}
.modal-content .form-block .form-group .cbcheckbox p {
  display: inline-block;
}
.numibia-tour.all-countries {
  max-width: inherit;
}
.main-content .country-details-info p {
  color: #4d5766;
}

.owl-carousel {
  width: 100%;
  position: relative;
}

.owl-carousel .owl-dots {
  content: "";
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  left: 50%;
  margin-left: -38px;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 6px 8px;
  bottom: 25px;
}

.owl-carousel .owl-dots button {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  border: 2px solid #4d5766;
  margin: 0 5px;
}

.owl-carousel .owl-dots button.active {
  background-color: #4d5766;
}

.owl-carousel .owl-nav button {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -22px;
}

.owl-carousel .owl-nav button i {
  background-color: #fff;
  width: 44px;
  height: 44px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #048dd3;
  font-size: 30px;
}

.owl-carousel .owl-nav button.owl-prev {
  left: 30px;
}

.owl-carousel .owl-nav button.owl-next {
  right: 30px;
}

.owl-carousel .item {
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  height: 424px;
}

.owl-carousel .item1 {
  background-image: url(../images/nicca2.png);
}

.owl-carousel .item1 .nice .individual,
.owl-carousel .item .nice .individual {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.owl-carousel .item1 .nice .individual h3,
.owl-carousel .item .nice .individual h3 {
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  color: #fff;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
}

.owl-carousel .item1 .nice .individual p,
.owl-carousel .item .nice .individual p {
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  color: #fff;
  font-weight: 700;
  font-style: normal;
  font-size: 40px;
}

.owl-carousel .item1 .nice .carnaval,
.owl-carousel .item .nice .carnaval {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.owl-carousel .item1 .nice .carnaval h2,
.owl-carousel .item .nice .carnaval h2 {
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  color: #fff;
  font-weight: 700;
  font-style: normal;
  font-size: 60px;
}

.owl-carousel .item1 .nice .carnaval p,
.owl-carousel .item .nice .carnaval p {
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  color: #fff;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 1.33;
}

.owl-carousel .item1 .nice .more,
.owl-carousel .item .nice .more {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 27px;
}

.owl-carousel .item1 .nice .more a,
.owl-carousel .item .nice .more a {
  text-decoration: none;
  color: #4d5766;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  background-color: #fff;
  padding: 5px 13px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.owl-carousel .item2 {
  background-image: url(../images/ispain.png);
}

.owl-carousel .item2,
.owl-carousel .item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.owl-carousel .item2 .spain,
.owl-carousel .item .spain {
  text-align: center;
}

.owl-carousel .item2 .spain .spain-tour h2,
.owl-carousel .item .spain .spain-tour h2 {
  font-size: 60px;
  font-weight: 700;
  font-style: normal;
  color: #4d5766;
}

.owl-carousel .item2 .spain .more,
.owl-carousel .item .spain .more {
  padding-top: 24px;
}

.owl-carousel .item2 .spain .more a,
.owl-carousel .item .spain .more a {
  text-decoration: none;
  color: #4d5766;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  background-color: #fff;
  padding: 5px 13px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.owl-carousel .item3 {
  background-image: url(../images/christmas.png);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.owl-carousel .item3 .christmas,
.owl-carousel .item .christmas {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.owl-carousel .item3 .christmas h3,
.owl-carousel .item .christmas h3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: Kurale;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  font-size: 40px;
  color: #fff;
  padding-top: 19px;
}

.owl-carousel .item3 .christmas h4,
.owl-carousel .item .christmas h4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  font-style: italic;
  color: #fff;
}

.owl-carousel .item3 .christmas h5,
.owl-carousel .item .christmas h5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  font-size: 20px;
  color: #fff;
  padding-top: 43px;
}

.owl-carousel .item3 .christmas h6,
.owl-carousel .item .christmas h6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-style: italic;
  font-weight: 400;
  line-height: 1.1;
  font-size: 20px;
  color: #fff;
  padding-top: 66px;
}
.tour .choose-tour .form-group,
.searchbox .form-group {
  position: relative;
  width: 250px;
}

.tour .choose-tour .form-group label,
.searchbox .form-group label {
  position: absolute;
  right: 10px;
  top: 6px;
}

.tour .choose-tour .form-group input,
.searchbox .form-group input {
  padding: 8px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #abb5ba;
  color: #4d5766;
  width: 100%;
}

.tour .choose-tour .form-group i,
.searchbox .form-group i {
  color: #4d5766;
}
.tour .map .our-tours .all-tours,
.tour .map .our-tours .box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #abb5ba;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  height: auto;
  position: relative;
  padding: 30px 17px;
  color: #4d5766;
}
.tour .map .our-tours .box.trips-by-country {
  text-align: center;
}
.tour .map .our-tours .box.trips-by-country > ul {
  display: inline-block;
}
.tour .map .our-tours .box.trips-by-country ul li {
  text-align: left;
}

.tour .map .our-tours .box.visible {
  display: flex;
}

.tour .map .our-tours .box a,
.tour .map .our-tours .box a:visited {
  color: #048dd3;
  text-decoration: none;
}
.tour .map .our-tours .box a:hover {
  color: #f2994a;
  text-decoration: none;
}

.tour .map .our-tours .box .widget {
  list-style: none;
  width: 201px;
  display: inline-block;
  padding: 0 11px;
  vertical-align: top;
}
.tour .map .our-tours .box .widget h6 {
  font-weight: normal;
  border-bottom: 1px solid #b3bdc3;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
}
.tour .map .our-tours .box .widget ul.menu {
  margin: 0 0 0 16px;
}
.tour .map .our-tours .box .widget ul.menu li {
  /* list-style-position: inside; */
  /* list-style: none; */
  /* background-image: url(images/li.png); */
  /* background-repeat: no-repeat; */
  /* background-position: 0 7px; */
  /* padding: 0 0 0 10px; */
  padding: 0;
  margin: 0;
}

.tour .map .our-tours .all-tours ul {
  display: block;
  width: 100%;
}

.tour .map .our-tours .all-tours li {
  display: inline-block;
  text-align: center;
  list-style-type: none;
  padding: 10px 0;
  width: 16%;
}

.tour .map .our-tours .all-tours li a {
  width: 169px;
  display: block;
  padding: 60px 10px 0 10px;
  height: 20px;
  color: #505c6b;
  font-weight: bold;
  background-repeat: no-repeat;
  background-position: top center;
  margin: 20px auto 0;
  line-height: 2em;
}
.tour .map .our-tours .all-tours li a {
  background-size: contain;
}
.tour .map .our-tours .all-tours .ico3 a {
  background-image: url(../images/tag/tag-poznavat.png);
}
.tour .map .our-tours .all-tours .ico1 a {
  background-image: url(../images/tag/tag-gastro.png);
}
.tour .map .our-tours .all-tours .ico2 a {
  background-image: url(../images/tag/tag-photo.png);
}
.tour .map .our-tours .all-tours .ico4 a {
  background-image: url(../images/tag/tag-extreme.png);
}
.tour .map .our-tours .all-tours .ico5 a {
  background-image: url(../images/tag/tag-cruise.png);
}
.tour .map .our-tours .all-tours .ico6 a {
  background-image: url(../images/tag/tag-otdyh.png);
}

.tour .map .our-tours .tour-type ul li {
  list-style: none;
  text-decoration: none;
}

.tour .map .our-tours .tour-type ul li {
  display: inline-block;
  text-decoration: none;
  font-size: 18px;
  color: #fff;
  padding: 4px 16px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  margin: 0 1px;
}

.tour .map .our-tours .tour-type ul li.current,
.tour .map .our-tours .tour-type ul li:hover {
  background-color: #fff;
  color: #4d5766;
  cursor: pointer;
}
.tour .map .our-tours .trips-by-date .menu-puteshestviya-po-mesyacam-container {
  max-width: 320px;
  margin: 0 auto;
}

.tour .map .our-tours .trips-by-date > div > ul {
  list-style: none;
  margin: 0 auto;
}
.tour .map .our-tours .trips-by-date > div > ul > li {
  list-style: none;
  display: inline-block;
  text-align: left;
  margin: 0 20px;
  vertical-align: top;
}
.tour .map .our-tours .trips-by-date > div > ul > li > a {
  border-bottom-color: rgb(179, 189, 195);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: rgb(80, 92, 107);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 21px;
  text-decoration: none;
}

.pager-bottom {
  padding: 40px 15px 93px 15px;
}

.wp-pagenavi {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 15px;
}

.wp-pagenavi span.pages {
  color: #4d5766;
  font-size: 16px;
  padding-right: 45px;
}

.wp-pagenavi a,
.wp-pagenavi span {
  margin-right: 18px;
  font-size: 15px;
  color: #03719c;
  text-decoration: none;
}
.wp-pagenavi a {
  text-decoration: none;
  color: #048dd3;
  font-size: 20px;
  padding: 2px 8px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.wp-pagenavi a:hover {
  background-color: rgba(4, 141, 211, 0.15);
}
.wp-pagenavi span.current {
  color: #048dd3;
  font-size: 20px;
  background-color: rgba(4, 141, 211, 0.15);
  padding: 2px 8px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
section .wp-pagenavi .page {
  padding-top: 0;
}

.wp-pagenavi a.last {
  color: #048dd3;
  opacity: 0.5;
}
.city-details-tour,
.all-countries {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 59px;
}

.city-details-tour .sights,
.all-countries .numibia-biuty {
  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding: 15px 15px;
  text-decoration: none;
}
.all-contacts .journey .your-contacts .form-block .form-group button,
.all-contacts .journey .your-contacts .form-block .form-group input[type="submit"] {
  display: block;
  width: 100%;
  padding: 10px 0;
  background-color: #048dd3;
  color: #fff;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: none;
  font-size: 18px;
  font-weight: 700;
}
.our-trip .trips-data .trips-consultation {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #048dd3;
  padding: 28px 34px 37px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  position: -webkit-sticky;
  position: sticky;
  top: 220px;
  margin-bottom: 30px;
}
section .tour-info p {
  padding-top: 0;
}
.main-content section .christmas-tour p {
  padding: 20px 0;
  font-size: 17px;
  color: #4d5766;
  margin-bottom: 0;
}
section .tour-program h2 {
  font-weight: 700;
  font-size: 20px;
  color: #4d5766;
  padding-top: 38px;
}

section .tour-program img {
  padding-top: 1em;
  width: 100%;
  height: auto;
}
section .tour-program .wp-caption {
  width: 100% !important;
}
section .author-tour ul,
section .christmas-tour ul {
  padding-left: 20px;
  padding-bottom: 20px;
}

section .author-tour ul li,
section .christmas-tour ul li {
  font-size: 18px;
  color: #4d5766;
}

section .place-info p,
section .country-info p {
  color: #4d5766;
  line-height: 1.33;
  font-size: 18px;
}
section .tour-price .price-menu .first-menu,
section .tour-price .price-menu .second-menu {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.cw-ff-formreply {
  margin: 50px 0 !important;
}
section .numibia-tour .trip-country-details {
  display: block;
  padding-top: 0;
  padding-bottom: 34px;
  background-image: url(../images/sights-item7.png);
}
section .trip-country-details .country-details-info .tour-component p,
p.wp-caption-text {
  font-style: italic;
  text-align: center;
}
section .trip-country-details .newyear-tour .get-ticket .content #first ul li,
section .trip-country-details .newyear-tour .get-ticket .content #first ol li,
section .tour-price .price-menu .first-menu ul li,
section .tour-price .price-menu .first-menu ol li {
  display: -webkit-box;
  background: url(../images/check.png) no-repeat center left;
  margin: 0;
  padding: 4px 0 4px 16px;
  list-style: none;
  background-size: 10px 7px;
  background-position-y: 10px;
}
section .trip-country-details .newyear-tour .get-ticket .content #first ul li ul li,
section .trip-country-details .newyear-tour .get-ticket .content #first ol li ul li,
section .tour-price .price-menu .first-menu ul li ul li,
section .tour-price .price-menu .first-menu ol li ul li {
  margin-left: 18px;
  display: list-item !important;
  background: none;
  padding: 0;
  list-style-type: disc;
}

section .trip-country-details .newyear-tour .get-ticket .content #second ul li,
section .trip-country-details .newyear-tour .get-ticket .content #second ol li,
section .tour-price .price-menu .second-menu ul li,
section .tour-price .price-menu .second-menu ol li {
  background: url(../images/cross.png) no-repeat center left;
  margin: 0;
  padding: 4px 0 4px 16px;
  list-style: none;
  background-size: 9px 9px;
  background-position-y: 10px;
}
section .trip-country-details .newyear-tour .get-ticket .content #second ul li ul li,
section .trip-country-details .newyear-tour .get-ticket .content #second ol li ul li,
section .tour-price .price-menu .second-menu ul li ul li,
section .tour-price .price-menu .second-menu ol li ul li {
  margin-left: 18px;
  display: list-item !important;
  background: none;
  padding: 0;
  list-style-type: disc;
}

section .trip-country-details .newyear-tour .get-ticket .content #first ul li ul,
section .trip-country-details .newyear-tour .get-ticket .content #first ol li ul,
section .tour-price .price-menu .first-menu ul li ul,
section .tour-price .price-menu .first-menu ol li ul,
section .trip-country-details .newyear-tour .get-ticket .content #second ul li ul,
section .trip-country-details .newyear-tour .get-ticket .content #second ol li ul,
section .tour-price .price-menu .second-menu ul li ul,
section .tour-price .price-menu .second-menu ol li ul {
  display: inline-block;
  padding-top: 0;
}
section .trip-country-details .newyear-tour .manager .manager-img {
  width: 105px;
  height: 105px;
}
section .trip-country-details .newyear-tour .manager .manager-img img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
section .trip-country-details .newyear-tour .manager .manager-data a,
section .trip-country-details .newyear-tour .manager .manager-data a:visited {
  color: #4d5766;
  text-decoration: none;
}
section .trip-country-details .trips-place a {
  color: #048dd3;
  text-decoration: none;
}
#gallery-2 .gallery-item {
  float: none !important;
  width: 50% !important;
}
#gallery-2 .gallery-item img {
  border-radius: 5px;
  margin-bottom: 30px;
}
section .trip-country-details .tour-consuldation .trips-consultation .chek label,
section .trip-country-details .tour-consuldation .trips-consultation .chek label p,
.our-trip .trips-consultation .chek label p {
  color: #fff;
  margin-bottom: 0;
}
section .trip-country-details .tour-consuldation .trips-consultation .chek label a,
.our-trip .trips-consultation .chek label a {
  color: #fff;
  text-decoration: underline;
}
section .trip-country-details .tour-consuldation .trips-consultation .chek label .error {
  color: red;
}
section .trip-country-details .tour-consuldation .trips-consultation .data-button button,
section .trip-country-details .tour-consuldation .trips-consultation .data-button input[type="submit"],
.our-trip .trips-consultation .data-button input[type="submit"] {
  font-weight: 700;
  cursor: pointer;
  padding: 10px 0 105px;
}
section .country-background.nobg {
  background-image: none;
  width: 100%;
  height: 60px;
}
section .country-background h1 {
  color: #fff;
  font-size: 42px;
  text-shadow: 0px 0px 6px #4d5766;
}

.gallery img.size-full {
  width: 100%;
  height: auto;
  padding: 15px;
}
.gallery img {
  border: none !important;
}
.blog img.size-full,
.about-country .country-info .category-description img.size-full,
.category-blog img.size-full {
  width: 100%;
  height: auto;
}
.blog img.size-full,
.category-blog img.size-full {
  width: 49%;
}
.blog img.size-full.width-100 {
  width: 100%;
}

.about-country .country-info .gallery-item {
  width: inherit !important;
  /* padding:15px; */
  padding: 13px;
}
.about-country .country-info .gallery-item img {
  border: none !important;
}

.tour .map .our-tours .all-tours,
.tour .map .our-tours .box {
  display: none;
}
