section {
  .country-details-menu {
    padding-bottom: 27px;
    ul {
      display: flex;
      margin: 0 -16px;
      align-items: center;
      li {
        list-style: none;
        padding: 0 16px;
        a {
          text-decoration: none;
          border-bottom: 2px solid #048dd3;
          color: #048dd3;
          font-size: 20px;
          display: inline-block;
          position: relative;
          &:after {
            content: "\f105";
            position: absolute;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            top: 7px;
            right: -20px;
            color: #4d5766;
            font-size: 17px;
          }
        }
        span {
          color: #8b98a0;
          font-size: 20px;
        }
      }
    }
  }
}
section {
  .country-details-background {
    background-image: url(../images/italy.png);
    height: 360px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .tour-dates {
      display: flex;
      padding-top: 37px;
      .cristmas-tour {
        padding-left: 45px;
        h3 {
          font-weight: 500;
          font-size: 35px;
          color: #4d5766;
        }
        p {
          color: #4d5766;
          font-size: 18px;
        }
      }
      .date {
        padding-top: 13px;
        p {
          background-color: #048dd3;
          padding: 7px 12px;
          font-size: 25px;
          font-weight: bold;
          color: #ffffff;
          border-radius: 5px;
          white-space: nowrap;
        }
      }
    }
  }
}
section {
  .trip-country-details {
    .second-day {
      p {
        color: #4d5766;
        font-size: 18px;
      }
    }
    display: flex;
    padding-top: 34px;
    .country-details-info {
      max-width: 880px;
      padding-right: 30px;
      flex: 0 0 830px;
      .new-year {
        width: 100%;
        height: 415px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .ourtour-img {
        background-image: url(../images/sights-item7.png);
        height: 495px;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      .tour-component {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        p {
          color: #000000;
          font-size: 18px;
          font-style: italic;
        }
      }
      .tour-map {
        padding-top: 55px;
        padding-bottom: 90px;
        h3 {
          font-size: 25px;
          font-weight: bold;
          color: #4d5766;
          padding-bottom: 10px;
        }
        iframe {
          width: 100%;
          height: 540px;
        }
      }
    }
    .newyear-tour {
      max-width: 400px;
      .get-ticket {
        background-color: rgba(247, 247, 237, 0.9);
        padding: 25px;
        border-radius: 10px;
        .menu {
          padding-top: 40px;
          ul {
            display: flex;
            li {
              flex: 0 0 50%;
              max-width: 50%;
              list-style: none;
              a {
                text-decoration: none;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                color: #219653;
                display: block;
                text-align: center;
                padding: 10px 20px;
                &.second {
                  color: #cd4d4d;
                }
                &.active {
                  background-color: #ffffff;
                }
              }
            }
          }
        }
        .tab-content {
          li {
            font-size: 16px;
            color: #4d5766;
          }
          display: none;
          &.active {
            display: block;
          }
        }
        .content {
          padding: 10px;
          padding-top: 20px;
          background-color: #ffffff;
          border-radius: 5px;
          ul {
            li {
              display: flex;
              list-style: none;
              img {
                width: 10px;
                height: 7px;
                margin-top: 8px;
                margin-right: 7px;
                display: block;
              }
              i {
                width: 6px;
                height: 6px;
                margin-top: 5px;
                margin-right: 10px;
                display: block;
                color: #cd4d4d;
                font-size: 12px;
                display: inline-block;
              }
              ul {
                padding-left: 35px;
                li {
                  list-style: initial;
                  display: list-item;
                }
              }
            }
          }
        }
        .print-tour {
          display: flex;
          justify-content: center;
          padding-top: 53px;
          a {
            text-decoration: none;
            color: #048dd3;
            font-size: 16px;
          }
          img {
            width: 30px;
            height: 23px;
          }
        }
        .send-message {
          h3 {
            font-weight: bold;
            color: #048dd3;
            font-size: 18px;
          }
          p {
            color: #048dd3;
            font-size: 16px;
          }
        }
      }
      .manager {
        display: flex;
        padding-top: 25px;
        .manager-img {
          width: 115px;
          height: 115px;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
        .manager-data {
          padding-left: 17px;
          h4 {
            color: #4d5766;
            font-size: 16px;
          }
          h2 {
            font-weight: bold;
            font-size: 18px;
            color: #4d5766;
          }
          ul {
            li {
              list-style: none;
              font-size: 16px;
              color: #4d5766;
              span {
                font-size: 16px;
              }
            }
          }
        }
      }
      .ourtour-info {
        padding-top: 46px;
        ul {
          li {
            list-style: none;
            font-weight: 500;
            color: #4d5766;
            font-size: 16px;
            span {
              color: #4d5766;
              font-size: 16px;
              font-weight: normal;
            }
          }
        }
      }
      .request {
        padding-top: 35px;
        display: flex;
        justify-content: center;
        a {
          text-decoration: none;
          background-color: #048dd3;
          padding: 7px 80px;
          border-radius: 25px;
          color: #ffffff;
        }
      }
      .websites {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        .web-img {
          padding: 0 11px;
          a {
            text-decoration: none;
            img {
              height: 25px;
              width: 25px;
            }
          }
        }
      }
    }
    .trips-place {
      padding-top: 15px;
      text-align: center;
      align-items: center;
      p {
        color: #048dd3;
        font-size: 18px;
        span {
          &.fz24 {
            font-size: 24px;
          }
          &.fz10 {
            font-size: 10px;
          }
          &.fz35 {
            font-size: 35px;
          }
        }
      }
    }
    .gallery-tour {
      padding-top: 20px;
      h3 {
        color: #4d5766;
        font-weight: bold;
        font-size: 25px;
      }
    }
    .gallery {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -14px;
      .gallery-img {
        width: 50%;
        height: 185px;
        padding: 14px;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
    .tour-consuldation {
      .trips-consultation {
        align-items: center;
        background-color: #048dd3;
        padding: 28px 34px 37px;
        border-radius: 5px;
        .trips-travel {
          text-align: center;
          line-height: 30px;
          h3 {
            font-weight: bold;
            font-size: 25px;
            color: #ffffff;
          }
          p {
            font-size: 18px;
            color: #ffffff;
          }
        }
        .travel-data {
          padding-top: 30px;
          .form-block {
            .form-group {
              padding: 15px 0;
              input {
                width: 330px;
                padding: 10px 13px;
                border-radius: 5px;
                border: 1px solid #abb5ba;
                color: #4d5766;
                font-size: 16px;
              }
            }
          }
        }
        .chek {
          padding-top: 28px;
          label {
            display: flex;
            align-items: center;

            p {
              padding-left: 11px;
              font-size: 16px;
              color: #4d5766;
              line-height: 1;
              color: #ffffff;
            }
            input {
              width: 28px;
              height: 28px;
            }
          }
        }
        .data-button {
          padding-top: 24px;
          button {
            display: block;
            width: 330px;
            padding: 10px 0;
            background-color: #abb5ba;
            color: #ffffff;
            border-radius: 5px;
            border: none;
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
