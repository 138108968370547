.tour-head {
  border-bottom: none;
  position: static!important;
  margin-top: -200px;
  .header-info {
    .header-contacts {
      .adress {
        padding-top: 25px;
      }
    }
  }
}
section {
  .tour-date {
    padding-top: 50px;
    p {
      background-color: #048dd3;
      border-radius: 5px;
      color: #ffffff;
      font-weight: bold;
      font-size: 25px;
      padding: 5px 12px;
      display: inline-block;
    }
  }
  .country-details-tour {
    padding-top: 22px;
    h3 {
      color: #4d5766;
      font-weight: 500;
      line-height: 1.1;
      font-size: 35px;
    }
    p {
      padding-top: 53px;
      color: #4d5766;
      font-size: 18px;
    }
  }
  .tour-info {
    padding-top: 40px;
    ul {
      li {
        list-style: none;
        font-size: 18px;
        color: #4d5766;

        span {
          font-weight: bold;
          font-size: 18px;
          color: #4d5766;
        }
      }
    }
    p {
      padding-top: 37px;
      font-size: 17px;
      color: #4d5766;
    }
  }
  .christmas-tour {
    padding-top: 53px;
    h4 {
      font-weight: bold;
      color: #048dd3;
      font-size: 25px;
    }
    p {
      padding: 20px 0;
      font-size: 17px;
      color: #4d5766;
    }
  }
  .tour-program {
    padding-top: 39px;
    h3 {
      font-weight: bold;
      font-size: 25px;
      color: #048dd3;
    }
  }
  .first-day {
    padding-top: 38px;
    h4 {
      font-weight: bold;
      font-size: 20px;
      color: #4d5766;
    }
    p {
      font-size: 18px;
      color: #4d5766;
    }
  }
  .second-day {
    padding-top: 35px;
    h4 {
      color: #4d5766;
      font-weight: bold;
      font-size: 20px;
    }
    p {
      padding-bottom: 20px;
    }
  }
  .author-tour {
    padding-top: 40px;
    h3 {
      font-weight: bold;
      font-size: 25px;
      color: #048dd3;
    }
    ul {
      padding-left: 20px;
      padding-bottom: 20px;
      li {
        font-size: 18px;
        color: #4d5766;
      }
    }
  }
  .carnival {
    padding-top: 90px;
    h3 {
      color: #4d5766;
      font-weight: bold;
      font-size: 25px;
    }
    p {
      padding-top: 42px;
      color: #4d5766;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .about-carnival {
    padding: 48px 30px;
    padding-left: 0;
    position: relative;
    background-color: rgba(171, 181, 186, 0.15);
    margin-top: 15px;
    position: relative;
    .left{
      position: absolute;
      top: 75px;
      left: -32px;
      img{
        width: 24px;
        height: 24px;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      bottom: 0;
      right: 100%;
      width: 10000px;
      background-color: rgba(171, 181, 186, 0.15);
    }
    p {
      padding: 23px 0;
      line-height: 1.44;
      font-size: 18px;
      font-style: italic;
      color: #4d5766;
      img{
        width: 24px;
        height: 24px;
      }
    }
  }
  .place-info {
    padding-top: 34px;
    p {
      color: #4d5766;
      line-height: 1.33;
      font-size: 18px;
    }
  }
  .tour-price {
    padding: 25px;
    background-color: rgba(255, 199, 1, 0.05);
    margin-top: 20px;

    .price {
      padding-top: 37px;
      h3 {
        font-weight: bold;
        font-size: 25px;
        color: #4d5766;
      }
    }
    .price-menu {
      padding-top: 25px;
      display: flex;

      .first-menu {
        h3 {
          font-size: 16px;
          font-weight: 500;
          color: #219653;
        }
        ul {
          padding-top: 15px;
          li {
            list-style: none;
            display: flex;

            img {
              width: 10px;
              height: 7px;
              margin-top: 8px;
              margin-right: 7px;
              display: block;
            }
          }
        }
      }
      .second-menu {
        padding-left: 37px;
        h3 {
          color: #cd4d4d;
          font-size: 16px;
        }
        ul {
          padding-top: 15px;
          li {
            list-style: none;
            display: flex;
            align-items: flex-start;
            i {
              width: 6px;
              height: 6px;
              margin-top: 5px;
              margin-right: 7px;
              display: block;
              color: #cd4d4d;
            }
          }
        }
      }
      ul > li {
        ul {
          padding-left: 35px;
          li {
            list-style: initial!important;
            display: list-item!important;
          }
        }
      }
    }
  }
}
.print{
  display: none;
  .price-menu{
    overflow: hidden;
    display: block!important;
    .first-menu{
      float: left!important;
      width: 50%!important;
    }
  }
}
@media print { 
  @page { margin: 1px; 
          size: auto;}
  .print{
    display: block;
  }
  .page-container{
    display: none;
  }
}