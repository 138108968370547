.section-menu {
  padding: 30px 0 55px;
  ul {
    display: flex;
    margin: 0 -16px;
    align-items: center;
    flex-wrap: wrap;
    li {
      list-style: none;
      padding: 0 16px;
      a {
        text-decoration: none;
        border-bottom: 2px solid #048dd3;
        color: #048dd3;
        font-size: 20px;
        display: inline-block;
        position: relative;
        &:after {
          content: "\f105";
          position: absolute;
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          top: 7px;
          right: -20px;
          color: #4d5766;
          font-size: 17px;
        }
      }
      span {
        color: #4d5766;
        font-size: 20px;
      }
    }
  }
}
section {
  .country-background {
    background-image: url(../images/numbia.png);
    width: 100%;
    height: 360px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      color: #4d5766;
      font-weight: 500;
      font-size: 35px;
    }
  }
}
.about-country {
  display: flex;
  padding-top: 45px;
  .country-info {
    max-width: 880px;
    padding-right: 30px;
    .country-place {
      margin: 0 -15px;
      display: flex;
      
      ul {
        display: flex;
        padding-bottom: 5px;
        flex-wrap: wrap;
        li {
          list-style: none;
          padding: 0 5px;
          margin: 7px 0;
          border-right: 2px solid #048dd3;
          a {
            text-decoration: none;
            color: #048dd3;
            font-size: 18px;
            display: inline-block;
            line-height: 1;
          }
          &:last-child {
            border: none;
          }
        }
        .place-info {
          padding-top: 30px;
          p {
            font-size: 18px;
            color: #4d5766;
          }
        }
      }
    }
    .namibia-info {
      padding-top: 25px;
      h3 {
        font-weight: bold;
        font-size: 18px;
        color: #4d5766;
      }
      ul {
        padding-top: 20px;
        padding-left: 15px;
        li {
          color: #4d5766;
          font-size: 18px;
        }
      }
    }
    .country-img {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
      padding-bottom: 85px;
      .place-img {
        width: 189px;
        height: 191px;
        padding: 15px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  
}
